import React, { Fragment } from 'react';
import { navigate } from 'gatsby';
import '../../fonts/NeutraText-Bold.otf';
import '../../util/fontawesome';
import phoneIcon from '../../images/icons/Timberland-Phone-Circle.svg';
import heroHook from '../../hooks/heroHook';
import servicesHook from '../../hooks/servicesHook';
import uspsHook from '../../hooks/uspsHook';
import testimonialCarouselHook from '../../hooks/testimonialCarouselHook';
import Layout from '../../components/layout';
import ContactHeroSection from '../../components/ContactHeroSection/ContactHeroSection';
import Section from '../../components/Section/Section';
import SectionContent from '../../components/SectionContent/SectionContent';
import StatCounter from 'statcounter';

import SEO from '../../components/seo';
import styles from './contact-us.module.scss';

import Contact from '../../forms/Contact';

const AdContactUs = () => {
  const hero = heroHook();
  const services = servicesHook();
  const usps = uspsHook();
  const testimonials = testimonialCarouselHook();

  return (
    <Layout transparentHeader>
      <SEO title="Contact-us" />
      <ContactHeroSection
        heading={hero.heading}
        subHeading={hero.subHeading}
        imageFluid={hero.background.fluid}
      />
      <Section theme="dark-bg">
        <SectionContent className={styles.formWrapper} centered>
          <Fragment >
            <h5 className={styles.quoteHeading} >Get A Free Quote!</h5>
            <Contact />
            <p>Your information is Data Protected</p>
            <br />
            <p>We'll Be In Touch With a <b>no obligation</b> quote.</p>
            <br />
            <p>By submitting your details, you confirm that you have read and understood our website {"\t"}
              <a onClick={() => navigate('/privacypolicy')} style={{ cursor: 'pointer' }} className={styles.contactButton}>Privacy Policy</a>
            </p>
          </Fragment>
        </SectionContent>
      </Section>


      <Section theme='dark'>
        <SectionContent centered >
          <div className={styles.content}>
            <div className={styles.icon}>
              <img src={phoneIcon} alt="Phone contact" />
            </div>
            <div>
              <a className={['tel', styles.tel].join(' ')} href="tel:01566774200">01566 774200</a>
            </div>
          </div>
        </SectionContent>
      </Section>

      <Section theme='dark-bg'>
        <SectionContent centered>
          <div className={styles.galleryGroup}>
            <ul>
              <li><h4 className={styles.galleryHeading}>Browse Gallery</h4></li>
              <li onClick={() => navigate('/service/windows')} className={styles.galleryButton}><span className={styles.gallerySpan}>Windows</span></li>
              <li onClick={() => navigate(`/service/doors`)} className={styles.galleryButton}><span className={styles.gallerySpan}>Doors</span></li>
              <li onClick={() => navigate(`/service/conservatories`)} className={styles.galleryButton}><span className={styles.gallerySpan}>Conservatories</span></li>
              <li onClick={() => navigate(`/service/bespoke-joinery`)} className={styles.galleryButton}><span className={styles.gallerySpan}>Bespoke Joinery</span></li>
            </ul>
          </div>

        </SectionContent>
      </Section>
      <StatCounter sc_project={12980163} sc_security="0dbe8d14" />
    </Layout>
  );
};

export default AdContactUs;
