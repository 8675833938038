import React from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import styles from './ContactHeroSection.module.scss';
import emailIcon from '../../images/icons/Timberland-Mail-Circle.svg';

import logo from '../../images/Timberland-Logo.svg';
import scrollIcon from '../../images/icons/Timberland-scroll.svg';

const ContactHeroSection = ({
  heading,
  subHeading,
  imageFluid,
  children,
}) => {
  const wrapperStyles = [
    styles.wrapper,
  ].join(' ');

  return (
    <BackgroundImage
      Tag="section"
      className={wrapperStyles}
      fluid={imageFluid}
      height={10}
    >
      <div className={styles.backdrop} />
      <div className={styles.logoWrapper}>
        <img src={logo} alt="Timberland Logo" />
      </div>
      <div className={styles.contentFlat}>
        {children}
      </div>
      <div className={styles.scrollWrapper}>
        <img src={scrollIcon} alt="Scroll down" />
      </div>
    </BackgroundImage>
  );
};

ContactHeroSection.propTypes = {
  heading: PropTypes.string.isRequired,
  subHeading: PropTypes.string.isRequired,
  imageFluid: PropTypes.object.isRequired,
};

export default ContactHeroSection;
